import { SearchOutput, Client } from 'mph-helper';

export class ClientsOutput extends SearchOutput {
  content: Array<Client>;

  constructor(payload: ClientsOutput) {
    super({
      page: payload.page,
      perPage: payload.perPage,
      pages: payload.pages,
      totalRecords: payload.totalRecords,
      allRecords: payload.allRecords,
    });
    this.content = payload.content.map((item) => new Client(item));
  }
}
