import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { Feature } from '@/common/constants/security';
import { routeGuardHelper } from '@/common/helpers/route-guard.helper';

const AppHeader = () => import(/* webpackChunkName: "client-companies" */ '@/components/app-header/index.vue');
const ClientCompanies = () => import(/* webpackChunkName: "client-companies" */ '@/features/client-companies/index.vue');
const AppFooter = () => import(/* webpackChunkName: "client-companies" */ '@/components/app-footer/index.vue');
const Search = () => import(/* webpackChunkName: "client-companies" */ '@/features/client-companies/search/index.vue');
const ClientCompany = () => import(/* webpackChunkName: "client-companies" */ '@/features/client-companies/client-company/index.vue');

export default {
  components: {
    header: AppHeader,
    default: ClientCompanies,
    footer: AppFooter,
  },
  name: 'clients',
  path: '/client-companies',
  redirect: { name: 'client-companies.search' },
  beforeEnter(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) {
    routeGuardHelper(next, Feature.readIstClientCompany);
  },
  children: [{
    component: Search,
    name: 'client-companies.search',
    path: 'search',
  }, {
    component: ClientCompany,
    name: 'client-companies.client-company',
    path: 'client-company/:id?',
  }],
};
