import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { Role } from 'mph-helper';
import AdminFormBuilder from '@/features/admin/form-builder';
import AdminClients from '@/features/admin/clients';
import AdminRoles from '@/features/admin/roles';
import AdminUsers from '@/features/admin/users';
import { routeGuardHelper } from '@/common/helpers/route-guard.helper';

const AppFooter = () => import(/* webpackChunkName: "admin" */ '@/components/app-footer/index.vue');
const Admin = () => import(/* webpackChunkName: "admin" */ '@/features/admin/index.vue');

export default {
  components: {
    default: Admin,
    footer: AppFooter,
  },
  name: 'admin',
  path: '/admin',
  redirect: { name: 'admin.form-builder' },
  beforeEnter(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) {
    routeGuardHelper(next, Role.superAdmin);
  },
  children: [
    AdminFormBuilder,
    AdminClients,
    AdminRoles,
    AdminUsers,
  ],
};
