const AdminUsers = () => import(/* webpackChunkName: "admin-users" */ '@/features/admin/users/index.vue');
const AdminSearch = () => import(/* webpackChunkName: "admin-users" */ '@/features/admin/users/search/index.vue');
const AdminUser = () => import(/* webpackChunkName: "admin-users" */ '@/features/admin/users/user/index.vue');

export default {
  component: AdminUsers,
  name: 'admin.users',
  path: 'users',
  redirect: { name: 'admin.users.search' },
  children: [{
    component: AdminSearch,
    name: 'admin.users.search',
    path: 'search',
  }, {
    component: AdminUser,
    name: 'admin.users.user',
    path: 'user/:id?',
  }],
};
