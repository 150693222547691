const AppHeader = () => import(/* webpackChunkName: "dashboard" */ '@/components/app-header/index.vue');
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/features/dashboard/index.vue');
const AppFooter = () => import(/* webpackChunkName: "dashboard" */ '@/components/app-footer/index.vue');

export default {
  components: {
    header: AppHeader,
    default: Dashboard,
    footer: AppFooter,
  },
  name: 'dashboard',
  path: '/dashboard',
};
