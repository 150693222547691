import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { Role } from 'mph-helper';
import { routeGuardHelper } from '@/common/helpers/route-guard.helper';

const EmailTemplates = () => import(/* webpackChunkName: "settings" */ '@/features/settings/email-templates/index.vue');
const Search = () => import(/* webpackChunkName: "settings" */ '@/features/settings/email-templates/search/index.vue');
const EmailTemplate = () => import(/* webpackChunkName: "settings" */ '@/features/settings/email-templates/email-template/index.vue');

export default {
  component: EmailTemplates,
  name: 'settings.email-templates',
  path: 'email-templates',
  redirect: { name: 'settings.email-templates.search' },
  beforeEnter(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) {
    routeGuardHelper(next, Role.superAdmin);
  },
  children: [{
    component: Search,
    name: 'settings.email-templates.search',
    path: 'search',
  }, {
    component: EmailTemplate,
    name: 'settings.email-templates.email-template',
    path: 'email-template/:id?',
  }],
};
