const AdminRoles = () => import(/* webpackChunkName: "admin-roles" */ '@/features/admin/roles/index.vue');
const AdminSearch = () => import(/* webpackChunkName: "admin-roles" */ '@/features/admin/roles/search/index.vue');
const AdminRole = () => import(/* webpackChunkName: "admin-roles" */ '@/features/admin/roles/role/index.vue');

export default {
  component: AdminRoles,
  name: 'admin.roles',
  path: 'roles',
  redirect: { name: 'admin.roles.search' },
  children: [{
    component: AdminSearch,
    name: 'admin.roles.search',
    path: 'search',
  }, {
    component: AdminRole,
    name: 'admin.roles.role',
    path: 'role/:id?',
  }],
};
