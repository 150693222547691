import { Operator, SecurityStore } from 'mph-helper';
import Store from '@/store';

export const Feature = {
  readSubscriptionProduct: 'READ_SUBSCRIPTION_PRODUCT',
  createSubscriptionDraft: 'CREATE_SUBSCRIPTION_DRAFT',
  submitSubscription: 'SUBMIT_SUBSCRIPTION',

  proceedSubscriptionOffer: 'PROCEED_SUBSCRIPTION_OFFER',
  mphSubscriptionManagement: 'MPH_SUBSCRIPTION_MANAGEMENT',
  subscriptionAmendmentRequest: 'SUBSCRIPTION_AMENDMENT_REQUEST',

  readIstClientCompany: 'READ_IST_CLIENT_COMPANY',
  writeIstClientCompany: 'WRITE_IST_CLIENT_COMPANY',
  deleteIstClientCompany: 'DELETE_IST_CLIENT_COMPANY',

  readInsuranceProduct: 'READ_INSURANCE_PRODUCT',
  writeInsuranceProduct: 'WRITE_INSURANCE_PRODUCT',
  deleteInsuranceProduct: 'DELETE_INSURANCE_PRODUCT',
  readInsuranceProductProviderPrices: 'READ_INSURANCE_PRODUCT_PROVIDER_PRICES',
  writeInsuranceProductProviderPrices: 'WRITE_INSURANCE_PRODUCT_PROVIDER_PRICES',
};

export const hasFeature = (feature: string | Array<string>, operator = Operator.or): boolean => Store.getters[`${SecurityStore.name}/hasFeature`](feature, operator);
