<template>
  <helper-dialog
    width="500px"
    ref="helperDialogRef"
    @open="handleDialogOpen"
    @close="handleReject"
    @closed="handleDialogClosed"
  >
    <template #header>
      <span v-if="step === Step.init">Archiving item</span>
      <span v-else-if="step === Step.decision">Please provide justification for your decision</span>
      <span v-else>Item cannot be archived</span>
    </template>
    <div :class="$style.content">
      <div v-if="step === Step.init">
        <span>Are you sure you would like to archive selected item?
          If you continue, the item will no longer be accessible in the system</span>
      </div>
      <el-form
        v-else-if="step === Step.decision"
        :model="model"
        :rules="Rules"
        require-asterisk-position="right"
        label-position="top"
        ref="elFormRef"
      >
        <el-form-item
          label="I want the item to be archived because ..."
          prop="reason"
        >
          <el-input
            v-model="model.reason"
            :autosize="{ minRows: Rows.min, maxRows: Rows.max }"
            type="textarea"
          ></el-input>
          <template #error="{ error }">
            <helper-form-error :error="error"></helper-form-error>
          </template>
        </el-form-item>
      </el-form>
      <div v-else>
        <span>The selected item cannot be archived because there are some related
          active items that need to be closed/archived first</span>
      </div>
    </div>
    <template #footer="{ close }">
      <div
        v-if="step === Step.init"
        :class="$style.footer"
      >
        <el-button
          text
          type="primary"
          @click="close"
        >
          <span>No abort the operation</span>
        </el-button>
        <el-button
          text
          type="primary"
          @click="goToStep(Step.decision)"
        >
          <span>Yes I would like to continue</span>
        </el-button>
      </div>
      <div
        v-else-if="step === Step.decision"
        :class="$style.footer"
      >
        <el-button
          text
          type="primary"
          @click="close"
        >
          <span>Abort the operation</span>
        </el-button>
        <el-button
          text
          type="primary"
          @click="handleResolve"
        >
          <span>Save and continue</span>
        </el-button>
      </div>
      <div
        v-else
        :class="$style.footer"
      >
        <el-button
          text
          type="primary"
          @click="close"
        >
          <span>Close</span>
        </el-button>
        <el-button
          text
          type="primary"
          @click="close"
        >
          <span>Show related items</span>
        </el-button>
      </div>
    </template>
  </helper-dialog>
</template>
<script lang="ts">
  import { defineComponent, ref, reactive, computed } from 'vue';
  import { FormInstance } from 'element-plus';
  import { HelperDialogInstance } from 'mph-helper';
  import i18n from '@/i18n';
  import Rules from '@/components/dialog-archive/rules.json';
  import { Rows } from '@/common/constants/autosize';

  const Step = {
    init: 0,
    decision: 1,
    error: 2,
  };

  export default defineComponent({
    setup(props, { expose }) {
      const helperDialogRef = ref<HelperDialogInstance>();
      const elFormRef = ref<FormInstance>();
      const step = ref(Step.init);
      const model = reactive({ reason: '' });
      const archiveResolve = ref();
      const archiveReject = ref();
      const locale = computed(() => i18n.global.locale);
      const archive = (): Promise<string> => {
        helperDialogRef.value?.open();
        return new Promise((resolve, reject) => {
          archiveResolve.value = resolve;
          archiveReject.value = reject;
        });
      };
      const goToStep = (payload: number) => {
        step.value = payload;
      };
      const handleDialogOpen = () => {
        step.value = Step.init;
      };
      const handleResolve = () => {
        elFormRef.value?.validate((valid) => {
          if (valid) {
            archiveResolve.value(model.reason);
            helperDialogRef.value?.close();
          } else {
            elFormRef.value?.$el.scrollIntoView({ behavior: 'smooth' });
          }
        });
      };
      const handleReject = () => {
        archiveResolve.value();
      };
      const handleDialogClosed = () => {
        model.reason = '';
      };
      expose({ archive });
      return {
        Step,
        Rows,
        Rules,
        helperDialogRef,
        elFormRef,
        step,
        model,
        locale,
        archive,
        goToStep,
        handleDialogOpen,
        handleResolve,
        handleReject,
        handleDialogClosed,
      };
    },
  });
</script>
<style lang="scss" module>
  @use '~mph-helper/dist/mixins/spaces';

  .content {
    @include spaces.dialog-padding;

    :global(.el-form-item) {
      margin-bottom: 0;
    }
  }
  .footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
</style>
