const RootClients = () => import(/* webpackChunkName: "settings" */ '@/features/settings/root-clients/index.vue');
const Search = () => import(/* webpackChunkName: "settings" */ '@/features/settings/root-clients/search/index.vue');
const Client = () => import(/* webpackChunkName: "settings" */ '@/features/settings/root-clients/client/index.vue');

export default {
  component: RootClients,
  name: 'settings.root-clients',
  path: 'root-clients',
  redirect: { name: 'settings.root-clients.search' },
  children: [{
    component: Search,
    name: 'settings.root-clients.search',
    path: 'search',
  }, {
    component: Client,
    name: 'settings.root-clients.client',
    path: 'client/:id?',
  }],
};
