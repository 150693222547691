import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { routeGuardHelper } from '@/common/helpers/route-guard.helper';
import { Feature } from '@/common/constants/security';

const InsuranceProducts = () => import(/* webpackChunkName: "settings" */ '@/features/settings/insurance-products/index.vue');
const Search = () => import(/* webpackChunkName: "settings" */ '@/features/settings/insurance-products/search/index.vue');
const SearchIndex = () => import(/* webpackChunkName: "settings" */ '@/features/settings/insurance-products/search/index/index.vue');
const SearchDrafts = () => import(/* webpackChunkName: "settings" */ '@/features/settings/insurance-products/search/drafts/index.vue');
const InsuranceProduct = () => import(/* webpackChunkName: "settings" */ '@/features/settings/insurance-products/insurance-product/index.vue');
const InsuranceProductIndex = () => import(/* webpackChunkName: "settings" */ '@/features/settings/insurance-products/insurance-product/index/index.vue');
const InsuranceProductDraft = () => import(/* webpackChunkName: "settings" */ '@/features/settings/insurance-products/insurance-product/draft/index.vue');
const ProviderPrices = () => import(/* webpackChunkName: "settings" */ '@/features/settings/insurance-products/provider-prices/index.vue');

export default {
  component: InsuranceProducts,
  name: 'settings.insurance-products',
  path: 'insurance-products',
  redirect: { name: 'settings.insurance-products.search' },
  beforeEnter(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) {
    routeGuardHelper(next, Feature.readInsuranceProduct);
  },
  children: [{
    component: Search,
    name: 'settings.insurance-products.search',
    path: 'search',
    redirect: { name: 'settings.insurance-products.search.index' },
    children: [{
      component: SearchIndex,
      name: 'settings.insurance-products.search.index',
      path: '',
    }, {
      component: SearchDrafts,
      name: 'settings.insurance-products.search.drafts',
      path: 'drafts',
    }],
  }, {
    component: InsuranceProduct,
    name: 'settings.insurance-products.insurance-product',
    path: 'insurance-product',
    redirect: { name: 'settings.insurance-products.insurance-product.index' },
    children: [{
      component: InsuranceProductIndex,
      name: 'settings.insurance-products.insurance-product.index',
      path: ':id?',
    }, {
      path: 'draft',
      redirect: { name: 'settings.insurance-products.insurance-product.index' },
      children: [{
        component: InsuranceProductDraft,
        name: 'settings.insurance-products.insurance-product.draft',
        path: ':id',
      }],
    }],
  }, {
    path: 'provider-prices',
    redirect: { name: 'settings.insurance-products.search' },
    children: [{
      component: ProviderPrices,
      name: 'settings.insurance-products.provider-prices',
      path: ':id',
      beforeEnter(
        to: RouteLocationNormalized,
        from: RouteLocationNormalized,
        next: NavigationGuardNext,
      ) {
        routeGuardHelper(next, Feature.readInsuranceProductProviderPrices);
      },
    }],
  }],
};
