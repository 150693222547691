<template>
  <div :class="$style.component">
    <el-tabs
      :class="$style.component__tabs"
      type="border-card"
    >
      <el-tab-pane
        v-for="(tab, index) in locales"
        :key="index"
      >
        <template #label>
          <icon-warning-filled
            v-if="isError(tab)"
            :class="$style['component__label-error']"
          ></icon-warning-filled>
          <span>{{ tab }}</span>
        </template>
        <slot :name="tab"></slot>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { LocaleI18n } from 'mph-helper';

  export default defineComponent({
    props: {
      errors: {
        type: Array as PropType<Array<string>>,
        default: () => [],
      },
    },
    setup(props) {
      const locales = Object.values(LocaleI18n);
      const isError = (payload: string) => props.errors.some((item) => item.includes(payload));
      return {
        locales,
        isError,
      };
    },
  });
</script>
<style lang="scss" module>
  .component {
    width: 100%;
    margin-bottom: 22px;

    .alert {
      margin-top: 12px;
    }
    &__label-error {
      margin-right: 8px;
      vertical-align: text-bottom;
      width: 20px;
      height: 20px;
    }
    &__tabs {
      :global {
        .el-form-item:last-child {
          margin: 0;
        }
      }
    }
  }
</style>
