import qs from 'qs';
import {
  createRouter,
  createWebHistory,
  LocationQuery,
  RouteRecordRaw,
} from 'vue-router';

import Admin from '@/features/admin';
import ClientCompanies from '@/features/client-companies';
import Dashboard from '@/features/dashboard';
import Error from '@/features/error';
import MisGenerator from '@/features/mis-generator';
import Scope from '@/features/scope';
import Settings from '@/features/settings';
import Subscriptions from '@/features/subscriptions';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'subscriptions' },
    children: [
      Admin,
      ClientCompanies,
      Dashboard,
      ...Error,
      MisGenerator,
      Scope,
      Settings,
      Subscriptions,
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  parseQuery(query) {
    return qs.parse(query) as LocationQuery;
  },
  stringifyQuery(query) {
    return qs.stringify({ ...query });
  },
});

export default router;
