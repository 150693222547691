import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { Role } from 'mph-helper';
import { routeGuardHelper } from '@/common/helpers/route-guard.helper';

const AppHeader = () => import(/* webpackChunkName: "scope" */ '@/components/app-header/index.vue');
const Scope = () => import(/* webpackChunkName: "scope" */ '@/features/scope/index.vue');
const AppFooter = () => import(/* webpackChunkName: "scope" */ '@/components/app-footer/index.vue');

export default {
  components: {
    header: AppHeader,
    default: Scope,
    footer: AppFooter,
  },
  name: 'scope',
  path: '/scope',
  beforeEnter(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) {
    routeGuardHelper(next, Role.superAdmin);
  },
};
