import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { Role } from 'mph-helper';
import { routeGuardHelper } from '@/common/helpers/route-guard.helper';

const InsuranceProviders = () => import(/* webpackChunkName: "settings" */ '@/features/settings/insurance-providers/index.vue');
const Search = () => import(/* webpackChunkName: "settings" */ '@/features/settings/insurance-providers/search/index.vue');
const Provider = () => import(/* webpackChunkName: "settings" */ '@/features/settings/insurance-providers/provider/index.vue');

export default {
  component: InsuranceProviders,
  name: 'settings.insurance-providers',
  path: 'insurance-providers',
  redirect: { name: 'settings.insurance-providers.search' },
  beforeEnter(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) {
    routeGuardHelper(next, Role.superAdmin);
  },
  children: [{
    component: Search,
    name: 'settings.insurance-providers.search',
    path: 'search',
  }, {
    component: Provider,
    name: 'settings.insurance-providers.provider',
    path: 'provider/:id?',
  }],
};
