/* eslint no-param-reassign: "error" */
import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';

export type THttpRequestConfig = AxiosRequestConfig & {
  globalLoader?: boolean;
}

export const baseURL = {
  api: '/api',
  oauth2: '/oauth2',
};

export const http: AxiosInstance = axios.create({
  baseURL: baseURL.api,
  headers: {
    Pragma: 'no-cache',
    'X-Requested-With': 'XMLHttpRequest',
  },
  globalLoader: true,
} as THttpRequestConfig);
