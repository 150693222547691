import qs from 'qs';
import { TSecurityState } from 'mph-helper';
import { http } from '@/http';
import { ClientInput } from '@/model/common/security/clients/clients-input';
import { ClientsOutput } from '@/model/common/security/clients/clients-output';

export class SecurityService {
  static getSecurity(): Promise<TSecurityState['security']> {
    return http.get('/security')
      .then(({ data }) => data);
  }

  static securityLogout(): Promise<void> {
    return http.get('/security/logout')
      .then(({ data }) => data);
  }

  static getListClient(payload?: ClientInput): Promise<ClientsOutput> {
    const query = qs.stringify(payload?.request, { addQueryPrefix: true });
    return http.get(`/security/list-clients${query}`)
      .then(({ data }) => new ClientsOutput(data));
  }

  static setClientScope(payload: number): Promise<undefined> {
    return http.post(`/security/list-clients/set-scope/${payload}`)
      .then(({ data }) => data);
  }
}
