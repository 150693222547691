import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_warning_filled = _resolveComponent("icon-warning-filled")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.component)
  }, [
    _createVNode(_component_el_tabs, {
      class: _normalizeClass(_ctx.$style.component__tabs),
      type: "border-card"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locales, (tab, index) => {
          return (_openBlock(), _createBlock(_component_el_tab_pane, { key: index }, {
            label: _withCtx(() => [
              (_ctx.isError(tab))
                ? (_openBlock(), _createBlock(_component_icon_warning_filled, {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['component__label-error'])
                  }, null, 8, ["class"]))
                : _createCommentVNode("", true),
              _createElementVNode("span", null, _toDisplayString(tab), 1)
            ]),
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, tab)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 3
    }, 8, ["class"])
  ], 2))
}