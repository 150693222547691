const AdminClients = () => import(/* webpackChunkName: "admin-clients" */ '@/features/admin/clients/index.vue');
const AdminSearch = () => import(/* webpackChunkName: "admin-clients" */ '@/features/admin/clients/search/index.vue');
const AdminClient = () => import(/* webpackChunkName: "admin-clients" */ '@/features/admin/clients/client/index.vue');

export default {
  component: AdminClients,
  name: 'admin.clients',
  path: 'clients',
  redirect: { name: 'admin.clients.search' },
  children: [{
    component: AdminSearch,
    name: 'admin.clients.search',
    path: 'search',
  }, {
    component: AdminClient,
    name: 'admin.clients.client',
    path: 'client/:id?',
  }],
};
