<template>
  <slot
    :is-dark="isDark"
    :toggle-dark="toggleDark"
  ></slot>
</template>
<script lang="ts">
  import { defineComponent } from 'vue';
  import { useDark, useToggle } from '@vueuse/core';

  export default defineComponent({
    setup() {
      const isDark = useDark();
      const toggleDark = useToggle(isDark);
      return {
        isDark,
        toggleDark,
      };
    },
  });
</script>
