import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { Role } from 'mph-helper';
import { routeGuardHelper } from '@/common/helpers/route-guard.helper';

const CustomDictionaries = () => import(/* webpackChunkName: "settings" */ '@/features/settings/custom-dictionaries/index.vue');
const Search = () => import(/* webpackChunkName: "settings" */ '@/features/settings/custom-dictionaries/search/index.vue');
const Dictionary = () => import(/* webpackChunkName: "settings" */ '@/features/settings/custom-dictionaries/dictionary/index.vue');
const Values = () => import(/* webpackChunkName: "settings" */ '@/features/settings/custom-dictionaries/values/index.vue');

export default {
  component: CustomDictionaries,
  name: 'settings.custom-dictionaries',
  path: 'custom-dictionaries',
  redirect: { name: 'settings.custom-dictionaries.search' },
  beforeEnter(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) {
    routeGuardHelper(next, Role.superAdmin);
  },
  children: [{
    component: Search,
    name: 'settings.custom-dictionaries.search',
    path: 'search',
  }, {
    component: Dictionary,
    name: 'settings.custom-dictionaries.dictionary',
    path: 'dictionary/:id?',
  }, {
    path: 'values',
    redirect: { name: 'settings.custom-dictionaries.search' },
    children: [{
      component: Values,
      name: 'settings.custom-dictionaries.values',
      path: ':code',
    }],
  }],
};
