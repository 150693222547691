import InsuranceProviders from '@/features/settings/insurance-providers';
import RootClients from '@/features/settings/root-clients';
import InsuranceProducts from '@/features/settings/insurance-products';
import CustomDictionaries from '@/features/settings/custom-dictionaries';
import EmailTemplates from '@/features/settings/email-templates';

const AppHeader = () => import(/* webpackChunkName: "settings" */ '@/components/app-header/index.vue');
const Settings = () => import(/* webpackChunkName: "settings" */ '@/features/settings/index.vue');
const AppFooter = () => import(/* webpackChunkName: "settings" */ '@/components/app-footer/index.vue');

export default {
  components: {
    header: AppHeader,
    default: Settings,
    footer: AppFooter,
  },
  name: 'settings',
  path: '/settings',
  redirect: { name: 'settings.insurance-providers' },
  children: [
    InsuranceProviders,
    RootClients,
    InsuranceProducts,
    CustomDictionaries,
    EmailTemplates,
  ],
};
