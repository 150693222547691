import { createStore, Module } from 'vuex';
import {
  RenditionsStore,
  SecurityStore,
  TSecurityState,
} from 'mph-helper';
import i18n from '@/i18n';
import { TRootState } from '@/store/type';
import { SecurityService } from '@/common/services/security.service';
import { RenditionsService } from '@/common/services/renditions.service';

export default createStore({
  actions: {
    getRenditions({ dispatch }) {
      return dispatch(`${RenditionsStore.name}/getRenditions`, {
        action: RenditionsService.getRenditions,
        i18n,
      });
    },
    getSecurity({ dispatch }) {
      return dispatch(`${SecurityStore.name}/getSecurity`, SecurityService.getSecurity);
    },
    securityLogout({ dispatch }) {
      return dispatch(`${SecurityStore.name}/securityLogout`, SecurityService.securityLogout);
    },
    async initApp({ dispatch }) {
      await dispatch(`${SecurityStore.name}/getSecurity`, SecurityService.getSecurity);
    },
  },
  modules: {
    [RenditionsStore.name]: RenditionsStore.module as Module<undefined, TRootState>,
    [SecurityStore.name]: SecurityStore.module as Module<TSecurityState, TRootState>,
  },
});
