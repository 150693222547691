<template>
  <el-config-provider
    :locale="ConfigLocale[locale]"
    size="large"
  >
    <el-container
      v-loading.fullscreen.lock="isLoading"
      :class="$style.component"
      direction="vertical"
    >
      <router-view
        :class="$style.component__header"
        name="header"
      ></router-view>
      <router-view
        :class="$style.component__default"
        name="default"
      ></router-view>
      <router-view
        :class="$style.component__footer"
        name="footer"
      ></router-view>
    </el-container>
    <helper-app-dialogs ref="helperAppDialogs"></helper-app-dialogs>
  </el-config-provider>
</template>
<script lang="ts">
  import { defineComponent, ref, computed, provide, onMounted } from 'vue';
  import en from 'element-plus/dist/locale/en';
  import fr from 'element-plus/dist/locale/fr';
  import { LoaderStore, HelperAppDialogsInstance, LocaleI18n } from 'mph-helper';
  import i18n from '@/i18n';

  const ConfigLocale = {
    [LocaleI18n.en]: en,
    [LocaleI18n.fr]: fr,
  };

  export default defineComponent({
    setup() {
      const helperAppDialogs = ref<HelperAppDialogsInstance>();
      const locale = computed(() => i18n.global.locale);
      const isLoading = computed(() => LoaderStore.getters.isLoading);
      onMounted(() => {
        provide('dialog', helperAppDialogs.value);
      });
      return {
        ConfigLocale,
        helperAppDialogs,
        locale,
        isLoading,
      };
    },
  });
</script>
<style lang="scss" module>
  .component {
    height: 100dvh;
    overflow: auto;

    &__default {
      flex-grow: 1;
      overflow: auto;
    }
  }
</style>
