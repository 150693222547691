import { NavigationGuardNext } from 'vue-router';
import { hasFeature } from '@/common/constants/security';

export const routeGuardHelper = (
  next: NavigationGuardNext,
  feature: string | Array<string>,
  operator?: string,
) => {
  if (hasFeature(feature, operator)) {
    next();
  } else {
    next({ name: 'error-404' });
  }
};
