import { createI18n } from 'vue-i18n';
import { LocaleI18n } from 'mph-helper';
import enGB from '@/i18n/en-GB.json';
import frFR from '@/i18n/fr-FR.json';

export default createI18n({
  locale: localStorage.getItem('locale') || LocaleI18n.fr,
  messages: {
    [LocaleI18n.en]: enGB,
    [LocaleI18n.fr]: frFR,
  },
});
