import { createApp } from 'vue';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { AxiosError } from 'axios';
import '@/styles/index.scss';
import ElementPlus from 'element-plus';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import {
  HelperAppDialogs,
  HelperAppFooter,
  HelperAppHeader,
  HelperDialog,
  HelperEditor,
  HelperErrorPage,
  HelperFormError,
  HelperFormSchema,
  HelperIframe,
  HelperLink,
  HelperQuerySearch,
  HelperTable,
} from 'mph-helper';
import { CommonComponents } from '@/components';
import App from '@/App.vue';
import AppError from '@/features/error/500/index.vue';
import store from '@/store';
import i18n from '@/i18n';
import router from '@/router';
import { useHttpInterceptors } from '@/http-interceptors';

export const app = createApp(App);

Object
  .entries(CommonComponents)
  .forEach(([key, value]) => {
    app.component(key, value);
  });

Object
  .entries(ElementPlusIconsVue)
  .forEach(([key, value]) => {
    app.component(`Icon${key}`, value);
  });

(async () => {
  try {
    useHttpInterceptors();
    await store.dispatch('initApp');
    app.use(ElementPlus);
    app.use(HelperAppDialogs);
    app.use(HelperAppFooter);
    app.use(HelperAppHeader);
    app.use(HelperDialog);
    app.use(HelperEditor);
    app.use(HelperErrorPage);
    app.use(HelperFormError);
    app.use(HelperFormSchema);
    app.use(HelperIframe);
    app.use(HelperLink);
    app.use(HelperQuerySearch);
    app.use(HelperTable);
    app.use(VueQueryPlugin, {
      queryClientConfig: {
        defaultOptions: {
          queries: {
            staleTime: Infinity,
            refetchOnWindowFocus: false,
            retry: 0,
          },
        },
      },
    });
    app.use(store);
    app.use(i18n);
    app.use(router);
    app.mount('#app');
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 500) {
      createApp(AppError)
        .use(ElementPlus)
        .use(HelperErrorPage)
        .mount('#app');
    }
  }
})();
