import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { routeGuardHelper } from '@/common/helpers/route-guard.helper';
import { Feature } from '@/common/constants/security';

const AppHeader = () => import(/* webpackChunkName: "subscriptions" */ '@/components/app-header/index.vue');
const Subscriptions = () => import(/* webpackChunkName: "subscriptions" */ '@/features/subscriptions/index.vue');
const AppFooter = () => import(/* webpackChunkName: "subscriptions" */ '@/components/app-footer/index.vue');
const Search = () => import(/* webpackChunkName: "subscriptions" */ '@/features/subscriptions/search/index.vue');
const Subscription = () => import(/* webpackChunkName: "subscriptions" */ '@/features/subscriptions/subscription/index.vue');

export default {
  components: {
    header: AppHeader,
    default: Subscriptions,
    footer: AppFooter,
  },
  name: 'subscriptions',
  path: '/subscriptions',
  redirect: { name: 'subscriptions.search' },
  beforeEnter(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) {
    routeGuardHelper(next, Feature.readSubscriptionProduct);
  },
  children: [{
    component: Search,
    name: 'subscriptions.search',
    path: 'search',
  }, {
    component: Subscription,
    name: 'subscriptions.subscription',
    path: 'subscription/:id?',
  }],
};
