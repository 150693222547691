const AppHeader = () => import(/* webpackChunkName: "mis-generator" */ '@/components/app-header/index.vue');
const MisGenerator = () => import(/* webpackChunkName: "mis-generator" */ '@/features/mis-generator/index.vue');
const AppFooter = () => import(/* webpackChunkName: "mis-generator" */ '@/components/app-footer/index.vue');

export default {
  components: {
    header: AppHeader,
    default: MisGenerator,
    footer: AppFooter,
  },
  name: 'mis-generator',
  path: '/mis-generator',
};
